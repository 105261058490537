import common from "@/common/mixins/common.js";

export default {
  mixins: [common],
  data() {
    return {
      afterSaleInformation: {},
      afterSaleInfoStatus: "",
      afterSaleInfo: {
        OrderAfter: {},
        OrderGoodsAfter: [],
        OrderAfterLog: {},
      },
      drawerStatus: {
        drawer: false,
        direction: "rtl",
      },
      //售后类型
      afterType: {
        value: "",
        data: [
          {
            value: "0",
            label: "质量问题",
          },
          {
            value: "1",
            label: "物流破损",
          },
        ],
      },
      //审核状态
      auditType: {
        value: "",
        data: [
          {
            value: "0",
            label: "待审核",
          },
          {
            value: "1",
            label: "部分通过",
          },
          {
            value: "2",
            label: "全部通过",
          },
          {
            value: "3",
            label: "全部拒绝",
          },
        ],
      },
      hdid: "",
      queryDate: [],
      tableData: [],
      pages: {
        page: 1,
        total: 100,
        size: 10,
      },
      csOptions: [
        {
          value: "10",
          label: "何虹均",
          disabled: true,
        },
        {
          value: "4",
          label: "戴冰冰",
          disabled: true,
        },
        {
          value: "5",
          label: "刘春燕",
          disabled: true,
        },
        {
          value: "7",
          label: "唐珊",
          disabled: true,
        },
        {
          value: "8",
          label: "冯韫琪",
          disabled: true,
        },
        {
          value: "20",
          label: "林莹",
          disabled: true,
        },
        {
          value: "38",
          label: "周绮莉",
          disabled: true,
        },
      ],
      csValue: "",
      csid: 0,
      refuse: false,
    };
  },
  created() {
    this.initCsOption();
    this.getData();
  },
  methods: {
    //设置售后订单商品状态
    setAfterGoodsStatus(afterGoods, status) {

      this.$prompt("请输入备注", "备注").then(({ value }) => {

        this.$axios
          .put("/a1/order/afterGoodsStatus/" + afterGoods.id + "/" + status,
            { id: afterGoods.id, status: status, remarks: value })
          .then(() => {
            this.$message.success("设置成功");
            //this.afterSaleInfo.OrderAfter.status = status
            this.getInfo(this.afterSaleInformation);
          });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "操作已取消",
        });
      });


    },
    //设置售后订单状态
    setAfterStatus() {

      if (+this.afterSaleInfoStatus === 0) {
        this.$message.info("请正确设置状态");
        return;
      }

      this.$confirm("设置后将提交后续审批流程，数据将锁定无法编辑, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        if (this.afterSaleInformation.id > 0) {
          let updateId=this.afterSaleInformation.id;
          let status=this.afterSaleInfoStatus;
          this.$axios
            .put("/a1/order/afterSale/status/" + updateId + "/" + status)
            .then(() => {
              this.$message.success("设置成功");
              this.afterSaleInfo.OrderAfter.status=status;
            });
        } else {
          this.$message.error("设置失败");
        }
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消设置",
        });
      });


    },
    //查询操作
    queryClick() {
      this.getData();
    },
    //获取详情
    getInfo(row) {
      this.afterSaleInformation=row;
      this.$axios.get("/a1/order/afterSale/" + row.id).then((res) => {
        this.afterSaleInfo.OrderAfter=res.data.data.OrderAfter;
        let orderGoodsAfter=res.data.data.OrderGoodsAfter;
        for (let i=0; i < orderGoodsAfter.length; i++) {
          orderGoodsAfter[i].imagesArr=orderGoodsAfter[i].images.split(",");
          orderGoodsAfter[i].goodsImgArr=orderGoodsAfter[i].goods_img.split(",");
        }
        this.afterSaleInfo.OrderGoodsAfter=orderGoodsAfter;
        this.afterSaleInfoStatus=res.data.data.OrderAfter.status + "";
        this.drawerStatus.drawer=true;

        this.afterSaleInfo.OrderAfterLog=res.data.data.OrderAfterLog;

      });
    },
    //分页跳转
    handleSizeChange(val) {
      this.pages.page=val;
      this.getData();
    },
    //获取数据
    getData() {
      //查询数据
      let queryData={
        page: this.pages.page,
        page_size: this.pages.size,
      };
      //时间选择
      if (this.queryDate != null) {
        queryData.bTime=this.queryDate[0];
        queryData.eTime=this.queryDate[1];
      }

      //申请类型
      if (this.afterType.value != "") {
        queryData.afterType=this.afterType.value;
      }
      //审核状态
      if (this.auditType.value != "") {
        queryData.status=this.auditType.value;
      }
      //海鼎ID
      if (this.hdid != "") {
        queryData.hdId=this.hdid;
      }

      let adminUser=JSON.parse(window.sessionStorage.getItem("user"));
      if (adminUser.admin_info.user !== "admin") {
        queryData.cs_id=adminUser.admin_info.id;
      }

      if (this.csid) queryData.cs_id=this.csid;

      this.$axios
        .get("/a1/order/afterSale", {
          params: queryData,
        })
        .then((res) => {
          const { data }=res.data;
          this.tableData=data.data;
          this.pages.page=data.page;
          this.pages.size=data.page_size;
          this.pages.total=data.total;

          let OrderGoodsAfter=this.tableData.OrderGoodsAfter;

          if (OrderGoodsAfter && OrderGoodsAfter.length > 0) {
            for (let i=0; i < OrderGoodsAfter.length; i++) {
              OrderGoodsAfter[i].imagesArr=OrderGoodsAfter[i].images.split(",");
              OrderGoodsAfter[i].goodsImgArr=OrderGoodsAfter[i].goods_img.split(",");
            }
          }
          
          this.tableData.OrderGoodsAfter=OrderGoodsAfter;

        });
    },
    //多选框
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection=val;
    },
    initCsOption() {

      const adminInfo=JSON.parse(sessionStorage.getItem("user"));
      if (!adminInfo || !([2, 4, 5, 6, 7, 8, 10, 20, 38].includes(adminInfo.admin_info.id))) {
        this.csOptions=[];
      }
      this.csValue=adminInfo.admin_info.user_name;
      this.csid=adminInfo.admin_info.id;

      this.csOptions.forEach(item => {
        if (item.label === adminInfo.admin_info.user_name) item.disabled=false;
      });

      switch (adminInfo.admin_info.id) {
        case 2:
        case 10:
          this.csOptions.forEach(item => {
            item.disabled=false;
          });
          break;
        case 4:
          this.csOptions.forEach(item => {
            if (item.label === "刘春燕") item.disabled=false;
          });
          break;
        case 7:
          this.csOptions.forEach(item => {
            if (item.label === "冯韫琪" || item.label === "林莹") item.disabled=false;
          });
          break;
      }
    },
    handleChangeCs() {
      this.csid=this.csValue;
      this.pages.page=1;
      this.getData();
    },
    handleRefund(id) {

      if (!this.refuse) {

        this.refuse=true;

        this.$axios.post("/a1/order/afterGoodsRefund/", { id: id })
          .then(() => {
            this.$message.success("正在申请退款，请稍后");
            this.refuse=false;
          })
          .catch(error => {
            this.refuse=false;
            this.$message.error(error.response.data.message);
          });

      }
    },
    // 导出
    exportOrderAfter() {
      //查询数据
      let queryData={};
      //时间选择
      if (this.queryDate != null) {
        queryData.bTime=this.queryDate[0];
        queryData.eTime=this.queryDate[1];
      }

      //申请类型
      if (this.afterType.value != "") {
        queryData.afterType=this.afterType.value;
      } else {
        queryData.afterType= -1;
      }
      //审核状态
      if (this.auditType.value != "") {
        queryData.status=parseInt(this.auditType.value);
      } else {
        queryData.status= -1;
      }
      //海鼎ID
      if (this.hdid != "") {
        queryData.hdId=parseInt(this.hdid);
      }

      let adminUser=JSON.parse(window.sessionStorage.getItem("user"));
      if (adminUser.admin_info.user !== "admin") {
        queryData.CsId=parseInt(adminUser.admin_info.id);
      }

      if (this.csid != "") queryData.CsId=parseInt(this.csid);

      //创建订单商品导出
      this.$axios.post("/a1/order/afterSale/export", queryData).then((res) => {
        let dowloadKey=res.data.data.to_key;
        //开始下载
        location.href=this.$root.DownUrl + "/c1/down/" + dowloadKey;
      });
    },
  },
};
