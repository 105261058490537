<template>
  <div>
    <div class="orderAfter">
      <el-select v-model="csValue" placeholder="请选择" size="mini" @change="handleChangeCs">
        <el-option
          v-for="item in csOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled">
        </el-option>
      </el-select>
      时间选择：
      <el-date-picker
        v-model="queryDate"
        default-time=""
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      &nbsp;&nbsp;&nbsp; 售后类型：
      <el-select v-model="afterType.value" clearable placeholder="请选择">
        <el-option
          v-for="item in afterType.data"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      &nbsp;&nbsp;&nbsp; 审核状态：
      <el-select v-model="auditType.value" clearable placeholder="请选择">
        <el-option
          v-for="item in auditType.data"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      &nbsp;&nbsp;&nbsp; 客户编码：
      <el-input
        v-model="hdid"
        placeholder="请输入海鼎ID"
        style="width: 150px"
      ></el-input>
      <el-button
        type="primary"
        @click="queryClick"
        style="margin-left: 10px"
        icon="el-icon-search"
        size="mini"
      >
        查询
      </el-button>
      <el-button size="mini" type="danger" @click="exportOrderAfter">导 出</el-button>

    </div>

    <div class="contentDiv">
      <el-table
        ref="multipleTable"
        border
        height="700"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column prop="hd_id" label="客户id" width="120" align="center">
        </el-table-column>
        <el-table-column prop="hd_name" label="门店" width="150" align="center"></el-table-column>
        <el-table-column prop="user_level" label="客户等级" width="80" align="center">
          <template v-slot="{row}">
            <span v-if="row.user_level === 1">A</span>
            <span v-if="row.user_level === 2">B</span>
            <span v-if="row.user_level === 3">C</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="after_item"
          label="申请订单号"
          width="220"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="quantity"
          label="商品数"
          width="220"
          align="center"
        >
        </el-table-column>
        <el-table-column label="申请金额" prop="total" />
        <el-table-column label="实际退款金额" prop="after_total_money">
          <template v-slot="{row}">
            {{ row.after_total_money.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column label="原因" align="center">
          <template v-slot="{ row }">
            <i v-if="row.order_pack_type === 0">质量问题</i>
            <i v-if="row.order_pack_type === 1">物流破损</i>
          </template>
        </el-table-column>

        <el-table-column label="申请时间" align="center" width="180">
          <template v-slot="{ row }">
            {{ row.created_at | formatDateStr }}
          </template>
        </el-table-column>

        <el-table-column label="商品状态" align="center">
          <template v-slot="{ row }">
            <span v-if="row.status === 0">待审核</span>
            <span v-if="row.status === 1">部分通过</span>
            <span v-if="row.status === 2">全部通过</span>
            <span v-if="row.status === 3">全部拒绝</span>
          </template>
        </el-table-column>
        <el-table-column label="单据状态" align="center">
          <template v-slot="{ row }">
            <span v-if="row.approval_status === 1">客服审核</span>
            <span v-if="row.approval_status === 2">A岗审核</span>
            <span v-if="row.approval_status === 3">营销中心审核</span>
            <span v-if="row.approval_status === 4">总裁审核</span>
            <span v-if="row.approval_status === 8">已拒绝</span>
            <span v-if="row.approval_status === 9">已完成</span>
          </template>
        </el-table-column>


        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <el-button type="primary" size="mini" @click="getInfo(row)"
            >详情
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div align="center">
        <el-pagination
          background
          @current-change="handleSizeChange"
          layout="prev, pager, next"
          :current-page="pages.page"
          :page-size="pages.size"
          :total="pages.total"
        />
      </div>

      <el-drawer size="75%" :with-header="false" :visible.sync="drawerStatus.drawer"
                 :direction="drawerStatus.direction">
        <div class="afterHeader">售后详情</div>
        <div style="padding: 10px; border: 1px solid #218da0; margin: 3px">
          <ul>
            <li>客户ID：{{ afterSaleInfo.OrderAfter.hd_id }}</li>
            <li>数量：{{ afterSaleInfo.OrderAfter.quantity }}</li>
            <li>客服：server</li>
            <li>金额：{{ afterSaleInfo.OrderAfter.total }}</li>
            <li>
              理由：
              <span v-if="afterSaleInfo.OrderAfter.order_pack_type === 0">质量问题</span>
              <span v-if="afterSaleInfo.OrderAfter.order_pack_type === 1">物流破损</span>
            </li>
            <li>
              时间：{{ afterSaleInfo.OrderAfter.created_at | formatDateStr }}
            </li>
            <li>
              当前状态：
              <span v-if="afterSaleInfo.OrderAfter.status === 0">待审核</span>
              <span v-if="afterSaleInfo.OrderAfter.status === 1">部分通过</span>
              <span v-if="afterSaleInfo.OrderAfter.status === 2">全部通过</span>
              <span v-if="afterSaleInfo.OrderAfter.status === 3">全部拒绝</span>
            </li>
            <li>
              设置状态：：
              <el-select v-model="afterSaleInfoStatus" placeholder="请选择">
                <el-option v-for="item in auditType.data"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </li>
            <li style="padding-top:10px">
              <el-button
                @click="setAfterStatus"
                type="primary"
                v-if="afterSaleInfo.OrderAfter.approval_status === 1"
                style="background: #218da0; border: #218da0">设置状态
              </el-button>
              <el-button
                @click="handleRefund(afterSaleInfo.OrderAfter.id)"
                v-if="afterSaleInfo.OrderAfter.approval_status === 9"
                type="primary"
                style="background: #218da0; border: #218da0"
              >自动退款
              </el-button>
              <span v-if="afterSaleInfo.OrderAfterLog && afterSaleInfo.OrderAfterLog.id > 0"
                    style="color:red;padding-left: 5px"
              >(已申请退款)</span>
            </li>
          </ul>
        </div>
        <div class="divTitle">商品信息</div>
        <div style="padding: 5px">
          <el-table
            :data="afterSaleInfo.OrderGoodsAfter"
            height="500"
            border
            style="width: 100%">
            <el-table-column
              prop="goods_code"
              label="条码"
              width="120"
              align="center">
            </el-table-column>
            <el-table-column
              prop="name"
              label="图片"
              width="180"
              align="center">
              <template v-slot="{ row }">
<!--                <viewer class="view-index">
                  <img
                    height="60"
                    :src="`http://hwimg.xmvogue.com/thumb/${row.goods_code}.jpg?x-oss-process=style/440`"
                    alt="" />
                </viewer>-->

                <el-image
                  v-if="row.presell_goods_img !=='' "
                  style="width: 100px; height: 100px;z-index: 9999"
                  :src="row.presell_goods_img"
                  :z-index="9999"
                  :preview-src-list="[row.presell_goods_img]">
                </el-image>
                <el-image
                  v-else
                  style="width: 100px; height: 100px;z-index: 9999"
                  :src="`http://hwimg.xmvogue.com/thumb/${row.goods_code}.jpg?x-oss-process=style/440`"
                  :z-index="9999"
                  :preview-src-list="[`http://hwimg.xmvogue.com/thumb/${row.goods_code}.jpg?x-oss-process=style/440`]">
                </el-image>
              </template>
            </el-table-column>

            <el-table-column prop="num" label="数量" align="center">
            </el-table-column>
            <el-table-column prop="price" label="单价" align="center" />
            <el-table-column label="总价" align="center">
              <template v-slot="{row}">
                <span>{{ (row.num * (row.price * 1000)) / 1000 }}  </span>
              </template>
            </el-table-column>
            <el-table-column prop="reason" label="理由" align="center">
            </el-table-column>
            <el-table-column prop="address" label="上传面单" align="center">
              <template v-slot="scope">
                <el-image
                  v-for="(item, index) in scope.row.imagesArr"
                  style="width: 100px; height: 100px;"
                  :src="item"
                  :z-index="9999"
                  :key="index"
                  :preview-src-list="scope.row.imagesArr">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="商品图片" align="center">
              <template v-slot="scope">
                <el-image
                  v-for="(item, index) in scope.row.goodsImgArr"
                  style="width: 100px; height: 100px;"
                  :src="item"
                  :z-index="9999"
                  :key="index"
                  :preview-src-list="scope.row.goodsImgArr">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="状态" align="center">
              <template v-slot="{ row }">
                <span v-if="row.status === 0">待审核</span>
                <span v-if="row.status === 1">拒绝</span>
                <span v-if="row.status === 2">同意</span>
              </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注" align="center" width="180">
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template v-slot="{ row }">
                <p v-if="row.status > 0">已处理</p>
                <br>
                <div v-if="afterSaleInfo.OrderAfter.approval_status === 1">
                  <el-button type="primary" size="mini" @click="setAfterGoodsStatus(row, 2)">通过</el-button>
                  <el-button type="danger" size="mini" @click="setAfterGoodsStatus(row, 1)">拒绝</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import orderAfter from "./js/index";

export default orderAfter;
</script>

<style scoped>
.orderAfter {
  border-bottom: 1px solid rgb(204, 204, 204);
  padding: 10px;
  color: #cc7832;
  font-size: 13px;
}

.contentDiv {
  border: 1px solid rgb(204, 204, 204);
  margin: 5px;
}

/* 头部 */
.afterHeader {
  background: #218da0;
  color: white;
  padding: 10px;
}

.divTitle {
  background: #218da0;
  padding: 8px;
  color: white;
}

.view-index{
  z-index: 9999;
}

</style>
